import * as React from 'react';
import {useEffect} from 'react';

import {CardItem, Item} from '../items/item.class';
import {ListDetailItemListItem} from '../lists/list-detail/ListDetailItemList';
import {PriceLine} from './PriceLine';
import {UsersService} from '../../client/users/users.service';
import {UserStateService} from '../../client/users/user-state.service';
import {User} from '../users/user.class';
import {useService} from '../react/ServiceContext';

interface PriceComponentProps {
    alias: string;
    className?: string;
    doNotShowEachPrice?: boolean;
    hideEachPrice?: boolean;
    isError?: boolean;
    item: CardItem | Item | ListDetailItemListItem;
    onModalDisplay?: () => void;
    returnUrl?: string;
    showCurrencyTooltip?: boolean;
    useCondensed?: boolean;
    user: User;
}

export const Price = ({
    alias,
    className = ``,
    doNotShowEachPrice = false,
    hideEachPrice = false,
    isError = false,
    item,
    onModalDisplay,
    returnUrl,
    showCurrencyTooltip,
    useCondensed = false,
    user,
}: PriceComponentProps) => {
    const componentName = `price-component-${alias}-${item.item}`;
    const usersService: UsersService = useService(`usersService`);
    const userStateService: UserStateService = useService(`userStateService`);

    /**
     * Invokes logon workflow
     */
    const logon = () => {
        usersService.logon(componentName, onModalDisplay, returnUrl);
    };

    // Process queued actions
    useEffect(() => {
        if (userStateService?.hasPendingAction(componentName, `logon`)) {
            logon();
        }
        // Only run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // For non view price users, do not render at all
    if (user.isLoggedIn() && !user.canViewPrice()) {
        return;
    }

    // Build priceObject
    const priceObject = {
        onSale: item.priceInfo?.specialNumber && item.priceInfo?.specialNumber !== '0',
        price: 0,
        unit: ``,
    };

    /**
     * Template
     */
    if ((!user.isLoggedIn() || user.canViewPrice()) && isError) {
        return <div>There was an error loading price</div>;
    }
    if (user.isLoggedIn()) {
        if (!item.priceInfo) {
            return <div>N/A</div>;
        }
        if (item.priceInfo && item.priceInfo.success === `OK`) {
            priceObject.price = item.priceInfo.priceCCalc;
            priceObject.unit = item.priceInfo.priceUnit;
        } else {
            return (
                <div className={`ImpItem_priceBox${className ? ` ${className}` : ``}`}>
                    <PriceLine
                        currency={item.priceInfo.currDesc}
                        error={item.priceInfo.error}
                        errorType={item.priceInfo.errorType}
                        onLogon={logon}
                        showCurrencyTooltip={showCurrencyTooltip}
                    />
                </div>
            );
        }
    } else if (item.webPrice) {
        return (
            <div className={`ImpItem_priceBox${className ? ` ${className}` : ``}`}>
                <PriceLine
                    doNotShowEachPrice={doNotShowEachPrice}
                    eachPrice={item.webPrice.priceEA}
                    hideEachPrice={hideEachPrice}
                    onLogon={logon}
                    onSale={false}
                    price={item.webPrice.pricePkg}
                    unit="ea"
                />
            </div>
        );
    } else {
        return (
            <PriceLine
                className={className}
                login
                onLogon={logon}
            />
        );
    }
    return (
        <div className={`ImpItem_priceBox${className ? ` ${className}` : ``}`}>
            <PriceLine
                currency={item.priceInfo?.currDesc}
                doNotShowEachPrice={doNotShowEachPrice}
                eachPrice={priceObject.price}
                hideEachPrice={hideEachPrice}
                onLogon={logon}
                onSale={priceObject.onSale}
                price={item.priceInfo?.pricePkg}
                showCurrencyTooltip={showCurrencyTooltip}
                unit={priceObject.unit}
            />
            {priceObject.onSale && !useCondensed && (
                <PriceLine
                    basePrice
                    className="tw-text-gray-400 tw-line-through"
                    currency={item.priceInfo?.currDesc}
                    doNotShowEachPrice={doNotShowEachPrice}
                    eachPrice={item.priceInfo?.bpriceCCalc}
                    hideEachPrice={hideEachPrice}
                    onLogon={logon}
                    price={item.priceInfo?.bpricePkg}
                    showCurrencyTooltip={showCurrencyTooltip}
                    unit={item.priceInfo?.bpriceUnit}
                />
            )}
        </div>
    );
};
