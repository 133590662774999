import * as React from 'react';
import {Dispatch, SetStateAction} from 'react';

import {AutoRoShipmentItem} from '../../auto-reorders/AutoReorderClass';
import {CardItem} from '../item.class';
import {CardOptions} from './ItemCard';
import {ContextMenu, ContextPosition} from '../../ui/ContextMenu/ContextMenu';
import {gaLegacyCustomEvent} from '../../../client/ga/ga-legacy.functions';
import {ItemToAdd} from '../../order-items/order-items.class';
import {ListsWorkflow} from '../../../client/lists/lists.workflow';
import {ShareLinkType} from '../../share-link/share-link.class';
import {showBarcodePopUp} from '../../../client/tools/show-barcode-pop-up';
import {UpdateItemsOnListActionTypes, UpdateListItem} from '../../lists/list.class';
import {User} from '../../users/user.class';
import {useService} from '../../react/ServiceContext';

interface ItemCardContextMenuProps {
    addItemToOrder: (cardItem: CardItem, qty: number, subComponentName: string) => void;
    cardItem: CardItem;
    cardOptions: CardOptions;
    componentName: string;
    deleteAutoRoItemConfirm: (autoRoShipmentItem: AutoRoShipmentItem, showModal: boolean) => void;
    editEnrollmentShow: (itemNum: string) => void;
    index: number;
    onEnrollAutoReorder: (itemToAdd: ItemToAdd) => void;
    onInventoryControl: (cardItem: CardItem) => void;
    onRemove: (itemNum: string) => void;
    onReplace: (oldItemNum: string, quantity: number, categoryPath: string, shipmentDate: string) => void;
    orderQty: number;
    position: ContextPosition;
    setShareLinkModal: Dispatch<SetStateAction<{linkId: string; linkType: ShareLinkType; show: boolean}>>;
    toggleAutoRoShipmentItemStatusConfirm: () => void;
    user: User;
}

export const ItemCardContextMenu = ({
    addItemToOrder,
    cardItem,
    cardOptions,
    componentName,
    deleteAutoRoItemConfirm,
    editEnrollmentShow,
    index,
    onEnrollAutoReorder,
    onInventoryControl,
    onRemove,
    onReplace,
    orderQty,
    position,
    setShareLinkModal,
    toggleAutoRoShipmentItemStatusConfirm,
    user,
}: ItemCardContextMenuProps) => {
    const listsWorkflow: ListsWorkflow = useService(`listsWorkflow`);

    /**
     * Adds provided item to list
     * @param action
     * @param updateListItems
     * @param subComponentName
     * @param itemDesc
     * @param itemImage
     * @private
     */
    const addToList = (
        action: UpdateItemsOnListActionTypes,
        updateListItems: UpdateListItem[],
        subComponentName: string,
        itemDesc: string,
        itemImage: string,
    ) => {
        listsWorkflow.addToList(action, updateListItems, subComponentName, subComponentName, itemDesc, itemImage);
    };

    /**
     * Shows barcode for provided itemNum
     * @param itemNum
     * @param listName
     * @param bin
     * @private
     */
    const generateBarcode = (itemNum: string, listName: string, bin: string) => {
        showBarcodePopUp(itemNum);
        gaLegacyCustomEvent({
            eventAction: `generate_barcode_${itemNum}` as any,
            eventCategory: listName as any,
            eventLabel: bin || ``,
        });
    };

    /**
     * Template
     */
    return (
        <ContextMenu
            position={position}
            index={index}
            items={[
                {
                    hidden: !cardItem.invoiceNumber,
                    name: `View Order`,
                    href: `/order-history/${encodeURIComponent(cardItem.invoiceNumber)}`,
                },
                {
                    hidden: !cardItem.invoiceNumber,
                    name: `View Purchase History`,
                    href: `/order-history?q=${encodeURIComponent(cardItem.id)}`,
                },
                {
                    hidden: !cardItem.uid,
                    name: `Return Item`,
                    href: `/order-history/${encodeURIComponent(cardItem.invoiceNumber)}/return?uid=${encodeURIComponent(cardItem.uid)}`,
                },
                {hidden: !cardItem.uid, divider: true},
                {
                    disabled: cardItem.discontinued,
                    hidden: !cardOptions.addToOrder,
                    name: `Add Item to Order`,
                    onClick: () => addItemToOrder(cardItem, 1, componentName),
                },
                {
                    hidden: !onEnrollAutoReorder || user.isPunchout,
                    name: `Enroll Item in Auto-Reorder`,
                    onClick: () => onEnrollAutoReorder({item: cardItem.item, unitsOrdered: orderQty}),
                },
                {
                    hidden: !cardOptions.addToList,
                    disabled: cardItem.discontinued,
                    name: `Add Item to List`,
                    onClick: () =>
                        addToList(
                            `add`,
                            [
                                {
                                    item: cardItem.item || cardItem.id,
                                    quantity: 1,
                                },
                            ],
                            componentName,
                            cardItem.name,
                            cardItem.imagePath,
                        ),
                },
                {
                    disabled: !cardOptions.canEdit,
                    hidden: !cardOptions.inventoryControls,
                    name: `${cardItem.comment ? 'Edit' : 'Add'} Comment`,
                    onClick: () => onInventoryControl(cardItem),
                },
                {
                    disabled: !cardOptions.canEdit,
                    hidden: !cardOptions.inventoryControls,
                    name: `Set Reorder Qty`,
                    onClick: () => onInventoryControl(cardItem),
                },
                {
                    hidden: !(cardOptions.categoryLink && cardItem.categoryPath),
                    name: `View Product Category`,
                    onClick: () => {
                        location.assign(`/browse/${cardItem.categoryPath}`);
                    },
                },
                {
                    hidden: !(cardOptions.locationLink && cardItem.bin),
                    name: `View Location`,
                    href: `/my-shop/${encodeURIComponent(cardItem.bin)}`,
                },
                {
                    disabled: !cardOptions.canEdit,
                    hidden: !cardOptions.remove,
                    name: `Remove Item`,
                    onClick: () => onRemove(cardItem.item),
                },
                {
                    hidden: !cardOptions.generateBarcode,
                    name: `Generate Barcode`,
                    onClick: () => generateBarcode(cardItem.item, componentName, cardItem.bin),
                },
                {
                    hidden: !cardOptions.autoReorder,
                    name: `Edit`,
                    onClick: () => editEnrollmentShow(cardItem.item),
                },
                {
                    hidden: !cardOptions.autoReorder,
                    name: cardItem.active ? `Make inactive` : `Make active`,
                    onClick: () => toggleAutoRoShipmentItemStatusConfirm(),
                },
                {
                    hidden: !onReplace || !cardItem.discontinued,
                    name: `Replace Item`,
                    onClick: () => onReplace(cardItem.item, orderQty, cardItem.categoryPath, cardOptions.nextShipment),
                },
                {
                    hidden: !cardOptions.autoReorder,
                    name: `Delete`,
                    onClick: () => deleteAutoRoItemConfirm(cardItem as AutoRoShipmentItem, true),
                },
                {
                    hidden: !cardOptions.showShareLink,
                    name: `Share Item`,
                    onClick: () => {
                        if (setShareLinkModal) {
                            setShareLinkModal({
                                linkId: cardItem.item,
                                linkType: `item`,
                                show: true,
                            });
                        }
                    },
                },
            ]}
        />
    );
};
