import * as React from 'react';
import {ElementType, FC, ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import {signal, useSignal, useSignalEffect, useComputed} from '@preact/signals-react';
import {useIsomorphicLayoutEffect} from 'usehooks-ts';

import {Debounce} from '../../tools/debounce';
import {GaItemListName, gaSelectItem, gaViewItemList} from '../../../client/ga/ga-ecommerce.functions';
import {InViewport} from '../../../client/ui/components/in-viewport';
import {MonetateService} from '../../../client/monetate/monetate.service';
import {useService} from '../../react/ServiceContext';

export interface ImpressionTrackerData {
    dimension16: string;
    index: number;
    item_id: string;
    item_list_name: GaItemListName;
    recToken?: string; // TODO: Determine association of this data
}

interface ImpressionTrackerProps {
    as?: ElementType;
    children: ReactNode;
    classes?: string;
    data: ImpressionTrackerData;
    detectPartial?: boolean;
    slidePosition?: any;
    trackClicks?: boolean;
    trackImpressions?: boolean;
}

const buildMonetateRecTokens = signal<string[]>([]);
const gaEvents = signal<ImpressionTrackerData[]>([]);

const ServerImpressionTracker: FC<ImpressionTrackerProps> = ({as: AsComponent = `div`, children, classes}) => {
    /**
     * Template
     */
    return <AsComponent className={classes}>{children}</AsComponent>;
};

const ClientImpressionTracker: FC<ImpressionTrackerProps> = ({
    as: AsComponent = `div`,
    children,
    classes,
    data,
    detectPartial = true,
    slidePosition,
    trackClicks = true,
    trackImpressions = true,
}) => {
    const ref = useRef(null);
    const fired = useSignal(false);

    // Track Click
    const trackClick = () => {
        if (trackClicks) {
            // Send to GA
            gaSelectItem([
                {
                    dimension16: data.dimension16,
                    index: data.index,
                    item_id: data.item_id,
                    item_list_name: data.item_list_name,
                },
            ]);
        }
    };

    /**
     * Builds tracking events for items visible to the user
     */
    const _trackImpressions = useCallback(() => {
        if (trackImpressions) {
            if (!fired.value && ref.current && data.recToken && InViewport.inViewport(ref.current, true)) {
                fired.value = true;
                buildMonetateRecTokens.value = [...buildMonetateRecTokens.value, data.recToken];
                gaEvents.value = [...gaEvents.value, data];
            } else {
                if (!fired.value && ref.current && InViewport.inViewport(ref.current, detectPartial)) {
                    fired.value = true;
                    gaEvents.value = [...gaEvents.value, data];
                }
            }
        }
    }, [data, detectPartial, fired, trackImpressions]);

    // Track impressions on initial page load
    useEffect(() => {
        _trackImpressions();
        // Only run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Track impressions as page is scrolled
    const scrollResizeListener = useCallback(() => {
        _trackImpressions();
    }, [_trackImpressions]);

    // Scroll & Resize
    useIsomorphicLayoutEffect(() => {
        const listener = Debounce(scrollResizeListener, 100);
        document.addEventListener(`scroll`, listener);
        document.addEventListener(`resize`, listener);

        if (slidePosition > 0) {
            setTimeout(() => {
                scrollResizeListener();
            }, 1000);
        }

        return () => {
            document.removeEventListener(`scroll`, listener);
            document.removeEventListener(`resize`, listener);
        };
    }, [slidePosition, scrollResizeListener]);

    /**
     * Template
     */
    return (
        <AsComponent
            className={classes}
            onClick={trackClick}
            ref={ref}
        >
            {children}
        </AsComponent>
    );
};
const ImpressionTracker: FC<ImpressionTrackerProps> = ({
    as: AsComponent = `div`,
    children,
    classes,
    data,
    detectPartial = true,
    slidePosition,
    trackClicks = true,
    trackImpressions = true,
}) => {
    const [renderClient, setRenderClient] = useState(false);
    const gaEventCount = useComputed(() => gaEvents.value.length);
    const monetateService: MonetateService = useService(`monetateService`);
    const recTokenCount = useComputed(() => buildMonetateRecTokens.value.length);
    useEffect(() => {
        setRenderClient(true);
    }, []);

    const fireMonetateEvents = Debounce(() => {
        if (recTokenCount.value > 0) {
            monetateService.sendRecoEvents({
                recToken: buildMonetateRecTokens.value,
            });
        }
        buildMonetateRecTokens.value = [];
    }, 500);
    const fireGAEvents = Debounce(() => {
        if (gaEventCount.value > 0) {
            gaViewItemList(gaEvents.value);
        }
        gaEvents.value = [];
    }, 500);

    useSignalEffect(() => {
        if (recTokenCount.value > 0) {
            fireMonetateEvents();
        }
        if (gaEventCount.value > 0) {
            fireGAEvents();
        }
    });

    if (!renderClient) {
        // You can show some kind of placeholder UI here
        return (
            <ServerImpressionTracker
                as={AsComponent}
                children={children}
                classes={classes}
                data={data}
                trackImpressions={trackImpressions}
            />
        );
    }

    return (
        <>
            <ClientImpressionTracker
                as={AsComponent}
                children={children}
                classes={classes}
                data={data}
                detectPartial={detectPartial}
                slidePosition={slidePosition}
                trackClicks={trackClicks}
                trackImpressions={trackImpressions}
            />
        </>
    );
};
export default ImpressionTracker;
