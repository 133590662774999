class Rectangle {
    constructor(
        public minX: number,
        public minY: number,
        public width: number,
        public height: number,
    ) {}

    get maxX() {
        return this.minX + this.width;
    }

    get maxY() {
        return this.minY + this.height;
    }

    contains(b: Rectangle) {
        return (
            Math.floor(b.maxX) <= Math.floor(this.maxX) &&
            Math.floor(b.minX) >= Math.floor(this.minX) &&
            Math.floor(b.minY) >= Math.floor(this.minY) &&
            Math.floor(b.maxY) <= Math.floor(this.maxY)
        );
    }

    intersects(b: Rectangle) {
        return b.minX <= this.maxX && b.maxX >= this.minX && b.minY <= this.maxY && b.maxY >= this.minY;
    }
}

export class InViewport {
    /**
     * Detects elements in viewport
     * @param element {HTMLElement} The HTML element to check visibility for
     * @param detectPartial {boolean} If true, check if any part of the element is visible on the screen
     * @returns {boolean} Indicates if the element is in the viewport or not
     */
    static inViewport(element: HTMLElement, detectPartial: boolean): boolean {
        detectPartial = !!detectPartial; // if null or undefined, default to false
        const viewport = $(window);
        const vpWidth = viewport.width() || 0;
        const vpHeight = viewport.height() || 0;
        const viewportRect = new Rectangle(0, 0, vpWidth, vpHeight);
        const elementBB = element.getBoundingClientRect();
        const elementRect = new Rectangle(elementBB.left, elementBB.top, elementBB.width, elementBB.height);

        // Check if element is hidden via display: none or visibility: hidden
        const elementStyle = getComputedStyle(element);
        if (elementStyle.display === 'none' || elementStyle.visibility === 'hidden') {
            return false;
        }

        // Check if any ancestor element has display: none or visibility: hidden
        let ancestor = element.parentNode as HTMLElement | null;
        while (ancestor && ancestor !== document.documentElement) {
            const ancestorStyle = getComputedStyle(ancestor);
            if (ancestorStyle.display === 'none' || ancestorStyle.visibility === 'hidden') {
                return false;
            }
            ancestor = ancestor.parentNode as HTMLElement | null;
        }

        // Check if any ancestor element has overflow: hidden
        ancestor = element.parentNode as HTMLElement | null;
        while (ancestor && ancestor !== document.documentElement) {
            const ancestorStyle = getComputedStyle(ancestor);
            if (ancestorStyle.overflow === 'hidden') {
                const ancestorRect = ancestor.getBoundingClientRect();
                const ancestorRectObj = new Rectangle(ancestorRect.left, ancestorRect.top, ancestorRect.width, ancestorRect.height);
                if (!ancestorRectObj.contains(elementRect)) {
                    return false;
                }
            }
            ancestor = ancestor.parentNode as HTMLElement | null;
        }

        if (detectPartial) {
            return viewportRect.intersects(elementRect);
        } else {
            return viewportRect.contains(elementRect);
        }
    }
}
